<!--
 * @Author: dongjia
 * @Date: 2021-06-23 17:45:49
 * @LastEditTime: 2022-01-04 15:39:01
 * @LastEditors: aleaner
 * @Description: 转账记录列表筛选组件
 * @FilePath: \organization-admin\components\Member\TransferListFilter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="onExport"
    ref="layoutFilter">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="提交人：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入提交人">
          </el-input>
        </el-form-item>
        <el-form-item label="提交职务：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入提交职务">
          </el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="提交时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentCredentialList } from "../../api/member/member-transfer";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      parentPositionList: [],
    };
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
        })
        .catch(() => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出会员列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page };
      return documentCredentialList(postData).catch(() => {});
    },
  },
  created() {
    // this.getPositionLevel();
    //
    this.$nextTick(() => {
      this.$layoutFilter = this.$refs.layoutFilter;
    });
  },
  // watch: {
  //   positionIds(val) {
  //     this.position_id = val[val.length - 1] ? val[val.length - 1] : -1;
  //   }
  // },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

